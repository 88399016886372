import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import {
  formInit,
  renderFields,
  renderCredentialsFields,
  renderMock,
} from "./config";
import Button from "../../../atoms/Buttons/Button";
import DirectMailActionButton from "../../../organisms/Buttons/PreApprovedMailAction";
import arrow from "../../../../assets/home/arrow-right-white.png";
import { parseFormToFormat } from "../../../../utils/form/parsers";
import {
  sendLeadRemarketing,
  sendLandingPageRequest
} from "../../../../api/application";
import { validateForm } from "./validate";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;

  & .terms-wrapper {
    font-size: 12px;
  }

  .fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .personal-info-fields {
    grid-gap: 1.2rem;
    row-gap: 2.4rem;
  }

  .button-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    gap: 1.8rem;

    button[type="submit"] {
      min-width: 190px;
    }

    @media screen and (max-width: 436px) {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 991px) {
    & form {
      min-height: 385px;
    }
  }
`;

const FormComponent = ({ applyButtonLabel = "Get Started" }) => {
  const [form, setForm] = useState(formInit());
  const [isLoading, setLoading] = useState(false);
  const { search } = useLocation();
  const history = useHistory();

  const onChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: { ...prevState[name], value, message: "" },
    }));
  };

  useEffect(() => {
    window.analytics?.page("Application Form");
  });

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // don't save partial ssn
    const [isValid, updatedForm] = validateForm(form);
    if (!isValid) {
      setForm(updatedForm);
      return;
    }

    const payload = parseFormToFormat(form);

    const utm = new URLSearchParams();
    const params = new URLSearchParams(search);
    for (let [value, key] of params) {
      if (key.indexOf("utm_")) {
        utm.set(value, key);
      }
    }

    setLoading(true);
    console.log("::::::::CallingRemarketingLandingForm");
    // const response = await sendLeadRemarketingToPayplan({
    //   tenantKey: process.env.REACT_APP_PAYPLAN_TENANT_KEY,
    //   merchantKey: process.env.REACT_APP_PAYPLAN_MERCHANT_KEY,
    //   loanAmount: parseInt(payload.requestedLoan),
    //   reason: payload.reason,
    //   email: payload.email,
    //   ssn: payload.ssn,
    //   cellPhone: payload.phoneNumber,
    // });
    if (!payload.mockData || payload.mockData === "") {
      payload.mockData = "{}";
    }
    const response = await sendLandingPageRequest({
      email: payload.email,
      ssnNumber: payload.ssn,
      phone: payload.phoneNumber,
      reason: payload.reason,
      utm: utm.toString(),
      requestedLoanAmount: payload.requestedLoan,
      originationUrl: window.location.href,
      mockData: JSON.parse(payload.mockData),
    });
    setLoading(false);

    window.analytics?.identify({
      email: payload.email,
      utm: utm.toString(),
      originationUrl: window.location.href,
    });

    localStorage.clear();
    const { leadType, link, payplanExist } = response?.data || {};
    if (payplanExist && link) {
      window.location.href = link;
    } else if (!response.error && response.data && leadType) {
      window.location.href = link;
    } else {
      // localStorage.setItem("isProcessingApplication", true);
      history.push("/application/thankyou", {
        message: response?.error.message,
      });
    }
  };

  return (
    <Form onSubmit={onSubmitHandler}>
      <div className="fields">
        {renderFields(form).map(({ component: Component, ...field }) => {
          return <Component key={field.name} {...field} onChange={onChange} />;
        })}
      </div>
      <div className="fields personal-info-fields">
        {renderCredentialsFields(form).map(
          ({ component: Component, ...field }) => {
            return (
              <Component key={field.name} {...field} onChange={onChange} />
            );
          },
        )}
      </div>
      {/* check if has showMock in localstorage then render mock field */}
      {localStorage.getItem("showMock") && (
        <div className="fields">
          {renderMock(form).map(({ component: Component, ...field }) => {
            return (
              <Component key={field.name} {...field} onChange={onChange} />
            );
          })}
        </div>
      )}
      <div className="button-wrapper">
        <Button
          disabled={isLoading}
          type="submit"
          variant="primary"
          className="button-contained"
        >
          {applyButtonLabel}
          <img src={arrow} alt="arrow-right" />
        </Button>

        <DirectMailActionButton />
      </div>
    </Form>
  );
};

export default FormComponent;
